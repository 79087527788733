<template>
  <moe-page title="新增前台分类">
    <moe-form ref="frontcategoryForm" :model="frontcategoryParmas" :rules="rules" :defaultVerify="false" :showBack="false">
      <el-form-item label="上级分类" prop="parentId">
        <moe-cascader :disabled="IS_EDIT" placeholder="请选择上级分类" v-model="frontcategoryParmas.parentId" :options="frontcategoryList" :checkStrictly="true" @change="changeParentId"></moe-cascader>
      </el-form-item>

      <el-form-item label="分类名称" prop="name">
        <el-input v-model.trim="frontcategoryParmas.name" placeholder="请输入分类名称" maxlength="20" clearable />
      </el-form-item>

      <el-form-item label="分类图片" :prop="frontcategoryParmas.parentId.length === 2 ? 'coverUrl': ''" v-if="showCoverUrl">
        <moe-upload-file
          v-model="frontcategoryParmas.coverUrl"
          ossKey="SHOP_MEDIA"
          :default-file-list="coverList"
          upload-text="上传分类图片"
          @change="() => $refs['frontcategoryForm'].validateField('coverUrl')" />
      </el-form-item>

      <el-form-item label="排序">
        <el-input :value="frontcategoryParmas.sort" @input="(value) => frontcategoryParmas.sort = value.replace(/^\.+|[^\d]/g,'')" placeholder="请输入分类排序，数值越大排序越靠前" maxlength="10" clearable />
      </el-form-item>

      <template v-if="showLinkType">
        <el-form-item label="跳转类型" prop="linkType" v-if="frontcategoryParmas.parentId.length === 2">
          <el-select v-model="frontcategoryParmas.linkType" placeholder="请选择跳转类型" @change="(value) => changeLinkType(value)" clearable>
            <el-option v-for="(item, index) in linkTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-else label="跳转类型">
          <el-select v-model="frontcategoryParmas.linkType" placeholder="请选择跳转类型" @change="(value) => changeLinkType(value)" clearable>
            <el-option v-for="(item, index) in linkTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </template>

      <el-form-item label="站内链接" v-if="frontcategoryParmas.linkType === 'LINK'">
        <el-input v-model.trim="frontcategoryParmas.linkData" placeholder="请输入站内链接" clearable />
      </el-form-item>

      <el-form-item label="关联商品" prop="linkData" v-if="frontcategoryParmas.linkType === 'GOOD'">
        <el-input v-model.trim="frontcategoryParmas.linkData" placeholder="请输入关联商品ID" clearable />
      </el-form-item>

      <el-form-item label="品牌编号" prop="linkData" v-if="frontcategoryParmas.linkType === 'BRAND'">
        <el-input v-model.trim="frontcategoryParmas.linkData" placeholder="请输入品牌编号" clearable />
      </el-form-item>

      <el-form-item label="映射分类" prop="linkData" v-if="frontcategoryParmas.linkType === 'CATEGORY'">
        <moe-cascader :multiple="true" placeholder="请选择上级分类" v-model="frontcategoryParmas.linkData" :options="categoryList"></moe-cascader>
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <moe-radio-group v-model="frontcategoryParmas.status" :radioBoxList="statusList" placeholder="请选择状态"></moe-radio-group>
      </el-form-item>

      <template slot="tool">
        <el-button icon="el-icon-close" type="info" @click="handleClose()">关闭</el-button>
        <el-button icon="el-icon-upload2" type="primary" :loading="frontcategoryLoad" @click="handleSubmit()">{{ frontcategoryLoad ? '提交中' : '提交' }}</el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'ContentManageFrontCategoryAdd',
  computed: {
    showCoverUrl() {
      let boolean = true, { parentId } = this.frontcategoryParmas;
      if (!parentId.length || (parentId.length === 1 && (parentId[0] === 0))) {
        boolean = false;
      }
      return boolean
    },
    showLinkType() {
      let boolean = true, { parentId } = this.frontcategoryParmas;
      if (!parentId.length || (parentId.length === 1 && (parentId[0] === 0))) {
        boolean = false;
      }
      return boolean
    },
    linkTypeList() {
      let linkTypeList = this.$moe_lodash.cloneDeepData(this.$moe_data.frontCategoryLinkType),
      { parentId } = this.frontcategoryParmas
      if (parentId.length === 1) {
        linkTypeList = [...linkTypeList.slice(0, 1), ...linkTypeList.slice(2, 5)];
      } else if (parentId.length === 2) {
        linkTypeList = linkTypeList.slice(1, 3);
      }
      return linkTypeList
    },
    /** 是否为修改 */
    IS_EDIT() {
      if (this.frontcategoryParmas.id) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    const verify = this.$moe_verify.verifyForm
    const checkLinkType = (rule, value, callback) => {
      console.log('value', value)
      if (!value) {
        return callback(new Error('请选择跳转类型'));
      } else {
        callback();
      }
    }
    const checkLinkData = (rule, value, callback) => {
      let { linkType } = this.frontcategoryParmas;
      if (!value.length) {
        if (linkType === 'GOOD') {
          return callback(new Error(`请输入关联商品编号`));
        } else if (linkType === 'CATEGORY') {
          return callback(new Error(`请选择映射分类`));
        } else if (linkType === 'BRAND') {
          return callback(new Error(`请输入关联品牌编号`));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    const rules = {
      parentId: verify.isEmpty('请选择上级分类'),
      name: verify.isEmpty('请输入分类名称'),
      coverUrl: verify.isEmpty('请上传分类图片'),
      linkType: [{ required: true, validator: checkLinkType, trigger: ['blur', 'change'] }],
      linkData: [{ required: true, validator: checkLinkData, trigger: ['blur', 'change'] }],
      status: verify.isEmpty('请选择状态'),
    }
    return {
      frontcategoryList: [],
      frontcategoryParmas: {
        parentId: [],
        level: 1,
        name: '',
        coverUrl: '',
        sort: '',
        linkType: '',
        linkData: '',
        status: false,
      },
      coverList: [],
      rules,
      frontcategoryLoad: false,
      statusList: [
        { label: '启用', value: true },
        { label: '禁用', value: false },
      ],
      categoryList: [],
    }
  },
  methods: {
    /** 关闭当前页 */
    handleClose() {
      this.$moe_coordinator.navigateBack();
    },
    changeLinkType(value) {
      switch (value) {
        case 'CATEGORY':
          this.frontcategoryParmas.linkData = [];
          break;
        default:
          this.frontcategoryParmas.linkData = '';
          break;
      }
      this.$refs['frontcategoryForm'].validateField('changeLinkType')

    },
    changeParentId() {
      this.frontcategoryParmas.linkType = '';
      this.changeLinkType();
    },
    handleSubmit() {
      this.$refs['frontcategoryForm'].validate(() => {
        this.frontcategoryLoad = true;
        let { parentId, sort, linkType, linkData } = this.frontcategoryParmas, newLinkData = '', newLevel = 1;

        if (linkType === 'CATEGORY') {
          newLinkData = linkData.map((arr) => arr[2]).flat().join(',');
        }

        if (parentId.length === 1) {
          if (parentId[0] === 0) {
            newLevel = 1;
          } else {
            newLevel = 2;
          }
        } else {
          newLevel = 3;
        }

        if (this.IS_EDIT) {
          this.$moe_api.CONTENT.updateFrontCategory({
            ...this.frontcategoryParmas,
            parentId: parentId[parentId.length - 1],
            level: newLevel,
            sort: sort || 0,
            linkData: newLinkData || linkData
          }).then((data) => {
            if (data.code === 200) {
              this.$moe_msg.success('修改前台分类成功', {
                completionHandler: () => {
                  this.handleClose();
                }
              });
            } else {
              this.$moe_msg.error(data.message);
            }
          }).finally(() => {
            this.frontcategoryLoad = false;
          })
        } else {
          this.$moe_api.CONTENT.addFrontCategory({
            ...this.frontcategoryParmas,
            parentId: parentId[parentId.length - 1],
            level: newLevel,
            sort: sort || 0,
            linkData: newLinkData || linkData
          }).then((data) => {
            if (data.code === 200) {
              this.$moe_msg.success('新增前台分类成功', {
                completionHandler: () => {
                  this.handleClose();
                }
              });
            } else {
              this.$moe_msg.error(data.message);
            }
          }).finally(() => {
            this.frontcategoryLoad = false;
          })
        }
      })
    },
    /** 获取分类列表 */
    categoryListTree() {
      this.$moe_api.GOODS_API.categoryListTree().then((data) => {
        if (data.code === 200) {
          this.categoryList = data.result.map((item) => {
            return {
              // ...item,
              status: item.status,
              label: item.name,
              value: item.id,
              children: item.childrens && item.childrens.map((itemChildItem) => {
                return {
                  // ...itemChildItem,
                  status: itemChildItem.status,
                  label: itemChildItem.name,
                  value: itemChildItem.id,
                  children: itemChildItem.childrens && itemChildItem.childrens.map((itemChildItemChildItem) => {
                    return {
                      // ...itemChildItemChildItem,
                      status: itemChildItemChildItem.status,
                      label: itemChildItemChildItem.name,
                      value: itemChildItemChildItem.id
                    }
                  })
                }
              })
            }
          });
          this.getFrontCategoryDetail();
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 查询所有前台分类 */
    getFrontCategoryAll() {
      this.$moe_api.CONTENT.getFrontCategoryAll().then((data) => {
        if (data.code === 200) {
          this.frontcategoryList = data.result.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.id,
              children: item.children && item.children.map((itemChildItem) => {
                return {
                  ...itemChildItem,
                  label: itemChildItem.name,
                  value: itemChildItem.id,
                  children: '',
                }
              })
            }
          });
          this.frontcategoryList.unshift({ label: '根目录', value: 0 });
          this.categoryListTree();
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 查询前台分类详情 */
    getFrontCategoryDetail() {
      if (this.$route.query.id) {
        this.$moe_api.CONTENT.getFrontCategoryDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let { id, parentId, name, coverUrl, level, sort, linkType, linkData, status } = data.result;
            this.frontcategoryParmas = {
              id,
              parentId: [],
              name,
              coverUrl: this.$moe_yunHelper.formatterImageUrl(coverUrl),
              level,
              sort,
              linkType: linkType || '',
              linkData: linkType === 'CATEGORY' ? [] : linkData,
              status,
            }
            this.coverList = [{
              url: coverUrl,
              tempFilePath: this.$moe_yunHelper.getTempFilePath(coverUrl)
            }]

            if (this.frontcategoryList.length) {
              this.frontcategoryList.forEach((item) => {
                if (item.value === parentId) {
                  this.frontcategoryParmas.parentId = [item.value];
                }
                if (item.children && item.children.length) {
                  item.children.forEach((itemChildrenItem) => {
                    if (itemChildrenItem.value === parentId) {
                      this.frontcategoryParmas.parentId = [item.value, itemChildrenItem.value];
                    }

                    if (itemChildrenItem.children && itemChildrenItem.children.length) {
                      itemChildrenItem.children.forEach((itemChildrenItemChildrenItem) => {
                        if (itemChildrenItemChildrenItem.value === parentId) {
                          this.frontcategoryParmas.parentId = [item.value, itemChildrenItem.value];
                        }
                      })
                    }
                  })
                }
              })
            }

            if (this.categoryList.length && level === 3) {
              let newLinkData = linkData.split(','), newLinkDataArr = [];
              newLinkData.forEach((id) => {
                this.categoryList.forEach((item) => {
                  if (item.children.length) {
                    item.children.forEach((itemChildrenItem) => {
                      if (itemChildrenItem.children.length) {
                        itemChildrenItem.children.forEach((itemChildrenItemChildrenItem) => {
                          if (itemChildrenItemChildrenItem.value === Number(id)) {
                            newLinkDataArr = [[item.value, itemChildrenItem.value, itemChildrenItemChildrenItem.value], ...newLinkDataArr]
                          }
                        })
                      }
                    })
                  }
                })
              })
              this.frontcategoryParmas.linkData = newLinkDataArr;
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
  },
  mounted() {
    this.getFrontCategoryAll();
  }
}
</script>

<style lang="scss"></style>